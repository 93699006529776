/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "bootstrap3/dist/css/bootstrap.min.css"
import "../styles/font-awesome.css"
import "../styles/flexslider.css"
import "../styles/sequencejs-qubico.css"
import "../styles/colorbox.css"
import "../styles/qubico.css"
import "../styles/qubico-cyan.css"
import "../styles/qubico-blog.css"
import "../fonts/google-font-dosis.css"
import "../fonts/google-font-lato.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [openMenu, setOpenMenu] = useState(false)

  return (
    <>
      <div id="main-nav" className="navbar navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              dataToggle="collapse"
              dataTarget="#site-nav"
              onClick={() => setOpenMenu(!openMenu)}
            >
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
              <span className="icon-bar"></span>
            </button>

            {/* <!-- ======= LOGO ========--> */}
            <a
              className="navbar-brand scrollto"
              href="/#home"
              style={{ color: "#5fa922" }}
            >
              <i className="media-object icon-1 fa fa-leaf"></i> 다누리펜션
            </a>
          </div>

          <div
            id="site-nav"
            className={`navbar-collapse ${!openMenu ? "collapse" : ""}`}
            style={{ overflow: "scroll" }}
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/#about" className="scrollto">
                  다누리 소개
                </a>
              </li>
              <li className="submenu">
                <a>전경 & 편의시설</a>
                <ul>
                  <li>
                    <a href="/spaces/landscape">전경</a>
                  </li>
                  <li>
                    <a href="/spaces/barbecue">바베큐장</a>
                  </li>
                  <li>
                    <a href="/spaces/bench">야외테이블</a>
                  </li>
                  <li>
                    <a href="/spaces/common">기타 공동시설</a>
                  </li>
                </ul>
              </li>

              <li className="submenu ">
                <a>
                  <i className="fa fa-calendar"></i> 예약 관련
                </a>
                <ul>
                  <li>
                    <a href="/reservation/reserve">예약 하기</a>
                  </li>
                  <li>
                    <a href="/reservation/info">예약 종합 안내</a>
                  </li>
                  <li>
                    <a href="/reservation/note">유의사항 / 환불기준</a>
                  </li>
                  <li>
                    <a href="/reservation/faq">자주하시는 질문</a>
                  </li>
                  {/* <!-- <li>
              <a href="#">질문/답변</a>
            </li> --> */}
                </ul>
              </li>
              {/* <!-- <li className="submenu ">
                    <a href="blog/blog.html">추억하기</a>
                    <ul>
                        <li>
                            <a href="#">이용후기</a>
                        </li>
                        <li>
                            <a href="#">손님 사진첩</a>
                        </li>
                    </ul>
                </li>  --> */}
              <li>
                <a href="/#map" className="scrollto">
                  찾아오시는 길
                </a>
              </li>
              {/* <!-- <li className="submenu ">
          <a>가까운 여행지</a>
          <ul>
            <li>
              <a href="#">5분거리 (10km이하)</a>
            </li>
            <li>
              <a href="#">15분거리 (약 20km)</a>
            </li>
            <li>
              <a href="#">25분거리 (약 30km)</a>
            </li>
          </ul>
        </li> -->
        <!-- <li className="submenu ">
          <a>보성군 축제 & 이벤트</a>
          <ul>
            <li>
              <a href="#">다향제</a>
            </li>
            <li>
              <a href="#">빛축제</a>
            </li>
          </ul>
        </li> --> */}
            </ul>
          </div>
        </div>
      </div>
      <main style={{ overflow: "hidden" }}>{children}</main>

      <footer
        id="main-footer"
        className="color-bg light-typo bg-image"
        style={{ backgroundImage: `url(/images/main.jpg)` }}
      >
        <div className="container text-center">
          {/* <!-- <ul className="social-links">
    <li>
      <a href="#link">
        <i className="fa fa-twitter fa-fw"></i>
      </a>
    </li>
    <li>
      <a href="#link">
        <i className="fa fa-facebook fa-fw"></i>
      </a>
    </li>
    <li>
      <a href="#link">
        <i className="fa fa-google-plus fa-fw"></i>
      </a>
    </li>
    <li>
      <a href="#link">
        <i className="fa fa-dribbble fa-fw"></i>
      </a>
    </li>
    <li>
      <a href="#link">
        <i className="fa fa-linkedin fa-fw"></i>
      </a>
    </li>
  </ul> --> */}

          <div>
            <p>
              상호 : 다누리펜션 &nbsp; | &nbsp; 대표자 : 변재호 &nbsp; | &nbsp;{" "}
              <b>입금계좌: [농협] 649-02-098378 (예금주: 변재호)</b>
            </p>
            <p>
              소재지 : 전라남도 보성군 보성읍 녹차로 890-28 (지번주소: 보성군
              보성읍 봉산리 1233-6번지)
            </p>
            <p>
              <i className="fa fa-mobile"></i> 010-4709-7968{" "}
              <span style={{ fontSize: "12px" }}>또는</span> 010-6471-7968
              &nbsp;&nbsp; | &nbsp;&nbsp; <i className="fa fa-phone"></i>{" "}
              061)852-7968{" "}
            </p>
          </div>

          <p>Copyright &copy; 2018 Qus. All Rights Reserved.</p>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
